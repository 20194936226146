import * as React from 'react';
import TermsLayout from '../../components/termspage/TermsLayout';
import { TermsError, TermsLoadingPage } from '../../components';
import { purifyHTML } from '../../utilities/purify-html';
import { useStrapiStore } from '../../store/useStrapiStore';
import { removeSpacesFromString } from '../../utilities';

const LicensePage = () => {
  const { termsData, termsState } = useStrapiStore((state) => ({
    termsState: state.termsState,
    termsData: state.termsData,
  }));

  if (termsState === 'loading') {
    return <TermsLoadingPage />;
  }

  if (termsState === 'error') {
    return <TermsError />;
  }

  const irorunLicense = termsData['irorun-license'];

  return (
    <TermsLayout>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: purifyHTML(
              removeSpacesFromString(irorunLicense.page_content)
            ),
          }}
        />
      </div>
    </TermsLayout>
  );
};

export default LicensePage;
